import { useEffect, useState } from "react";
import { Header, Card, Euro } from "../ui";
import GraphTurnoverCumulated from "./GraphTurnoverCumulated";
import GraphTurnover from "./GraphTurnover";
import Indicators from "./Indicators";
import { useDispatch, useSelector } from "react-redux";
import { setResources } from "../resources/resourcesActions";
import { setTasks } from "../tasks/tasksActions";
import { getStats, getMonthInfo } from "../core/stats";
import { getResources, getTasks } from "../services/api";
import { months } from "./months";
import { RootState } from "../store";
import { DateTime } from "luxon";

function TotalLine({ data, ...props }: { data: number[][]; [k: string]: any }) {
  const total = data.reduce((acc, d, i) => {
    if (i === 0) {
      acc = new Array(d.length).fill(0);
    }
    for (let j = 0; j < d.length; j++) {
      const element = d[j];
      acc[j] += element;
    }
    return acc;
  }, [] as number[]);

  return (
    <tr {...props}>
      <td className="capitalize">Total</td>
      {total.map((t, i) => {
        const variation =
          i === 0 || total[i - 1] === 0 || total[i] === 0
            ? 0
            : (2 * 100 * (total[i] - total[i - 1])) / (total[i] + total[i - 1]);
        return (
          <td className="text-center">
            <Euro value={t} />
            <br />
            <span
              className={
                variation === 0
                  ? ""
                  : variation > 0
                  ? "text-green-500"
                  : "text-red-500"
              }
            >
              {variation > 0 && "+"}
              {Math.round(variation)}%
            </span>
          </td>
        );
      })}
    </tr>
  );
}

function HistoricalLine({
  month,
  data,
  onClick,
  ...props
}: {
  month: string;
  data: number[];
  onClick: (yearIndex: number) => void;
  [k: string]: any;
}) {
  return (
    <tr {...props}>
      <td className="capitalize">{month}</td>
      {data.map((d, i) => {
        const variation =
          i === 0 || data[i - 1] === 0 || data[i] === 0
            ? 0
            : (2 * 100 * (data[i] - data[i - 1])) / (data[i] + data[i - 1]);
        return (
          <td className="text-center cursor-pointer" onClick={() => onClick(i)}>
            <Euro value={d} />
            <br />
            <span
              className={
                variation === 0
                  ? ""
                  : variation > 0
                  ? "text-green-500"
                  : "text-red-500"
              }
            >
              {variation > 0 && "+"}
              {Math.round(variation)}%
            </span>
          </td>
        );
      })}
    </tr>
  );
}

function Radar() {
  const [monthSelected, setMonthSelected] = useState<Date | null>(null);
  const stats = useSelector((state: RootState) =>
    getStats(state.tasks, state.resources)
  );

  const monthInfo = useSelector((state: RootState) => {
    if (monthSelected) {
      return getMonthInfo(state.tasks, state.resources, monthSelected);
    }
    return null;
  });

  return (
    <>
      <div className="flex flex-col lg:flex-row -mx-2">
        <div className="w-full lg:w-1/3 mb-4 px-2">
          <div>
            <Card className="p-8">
              <h2 className="h2 mb-8">Chiffres d'affaires</h2>
              <GraphTurnover stats={stats} />
            </Card>
          </div>
        </div>
        <div className="w-full lg:w-1/3 mb-4 px-2">
          <div>
            <Card className="p-8">
              <h2 className="h2 mb-8">Chiffres d'affaires cumulés</h2>
              <GraphTurnoverCumulated stats={stats} />
            </Card>
          </div>
        </div>
        <div className="w-full lg:w-1/3 mb-4 px-2">
          <Indicators stats={stats} />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row -mx-2">
        <div className="w-full mb-4 md:mb-0 px-2">
          <Card className="p-8">
            <h2 className="h2">Historique</h2>
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="w-2/7 px-4 py-2">Mois</th>
                  {stats.historic.headers.map((header) => (
                    <th className="text-center w-1/7 px-4 py-2">{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {stats.historic.data.map((d, i) => (
                  <HistoricalLine
                    key={i}
                    data={d}
                    month={months[i]}
                    onClick={(yearIndex) => {
                      setMonthSelected(
                        new Date(Number(stats.historic.headers[yearIndex]), i)
                      );
                    }}
                  />
                ))}
                <TotalLine className="tr-total" data={stats.historic.data} />
              </tbody>
            </table>
          </Card>
        </div>
        <div className="w-full lg:w-1/2 mb-4 md:mb-0 px-2">
          {monthInfo !== null && monthSelected !== null && (
            <Card className="p-8">
              <h2 className="h2">
                {`Chantiers du mois de ${DateTime.fromJSDate(
                  monthSelected
                ).toFormat("MMMM yyyy")}`}
              </h2>
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="w-2/7 px-4 py-2">Chantier</th>
                    <th className="w-5/7 px-4 py-2">
                      Chiffre d'affaire du mois
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {monthInfo.map((m) => (
                    <tr key={m.id}>
                      <td>{m.name}</td>
                      <td>
                        <Euro value={m.monthTurnover} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default function RadarPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    getTasks().then((tasks) => dispatch(setTasks(tasks)));
    getResources().then((resources) => dispatch(setResources(resources)));
  }, [dispatch]);

  return (
    <div>
      <Header />
      <main className="p-4">
        <Radar />
      </main>
    </div>
  );
}
