import React from "react";
import { IStats } from "../core/types";
import { Card } from "../ui";

function Stat({
  title,
  value,
}: {
  title: string;
  value: string | React.ReactNode;
}) {
  return (
    <div className="p-6 text-center">
      <p className="text-2xl leading-none font-extrabold whitespace-no-wrap">
        {value}
      </p>
      <p className="mt-2 text-md leading-6 text-gray-500">
        {title}
      </p>
    </div>
  );
}

export default function Indicators({ stats }: { stats: IStats }) {
  return (
    <Card
      className="p-8 flex flex-col justify-around"
      style={{ height: "100%" }}
    >
      <div className="flex flex-col md:flex-row justify-between">
        <Stat
          value={`${stats.total.meanTurnoverPerWorkedDay}€`}
          title="CA / jour"
        />
        <Stat
          value={`${stats.total.meanTurnoverPerMonth}€`}
          title="CA / mois"
        />
        <Stat value={`${stats.total.meanTurnoverPerYear}€`} title="CA / an" />
      </div>
      <div className="flex flex-col md:flex-row justify-between">
        <Stat value={stats.total.nbTasks} title="Nb de chantiers" />
        <Stat
          value={`${stats.total.meanNbDaysWorkedPerTask} jours`}
          title="Nb jours moyen par chantier"
        />
        <Stat
          value={`${stats.total.meanTurnoverPerTask}€`}
          title="CA moyen par chantier"
        />
      </div>
    </Card>
  );
}
