import * as types from "./tasksActionsTypes";

const initialState = {};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case types.SET_TASK:
      return {
        ...state,
        [action.task.id]: action.task,
      };
    case types.REMOVE_TASK:
      delete state[action.task.id];
      return {
        ...state,
      };
    case types.SET_TASKS:
      return action.tasks.reduce(
        (acc, task) => {
          acc[task.id] = task;
          return acc;
        },
        { ...state }
      );
    case types.UPDATE_SEGMENT:
      return {
        ...state,
        [action.segment.taskId]: {
          ...state[action.segment.taskId],
          segments: state[action.segment.taskId].segments.map((segment) => {
            const segmentToUpdate = action.segment;
            if (segment.id !== segmentToUpdate.id) return segment;
            return {
              id: segmentToUpdate.id,
              resource: segmentToUpdate.resource,
              start: segmentToUpdate.start,
              end: segmentToUpdate.end,
              taskId: segmentToUpdate.taskId,
              resourceId: segmentToUpdate.resourceId,
            };
          }),
        },
      };
    case types.DOCUMENT_DELETED:
      return {
        ...state,
        [action.task.id]: {
          ...state[action.task.id],
          documents: state[action.task.id].documents.filter(
            (document) => document.id !== action.document.id
          ),
        },
      };
    default:
      return state;
  }
}
