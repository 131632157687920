import { Formik } from "formik";
import * as Yup from "yup";
import { Input } from "../ui";

export const ChangePasswordSchema = Yup.object({
  current_password: Yup.string()
    .min(8, "Votre mot de passe actuel est trop court")
    .required("Votre mot de passe actuel est obligatoire"),
  new_password: Yup.string()
    .min(8, "Ce mot de passe est trop court")
    .required("Le nouveau mot de passe est obligatoire"),
  re_new_password: Yup.string()
    .min(8, "Ce mot de passe est trop court")
    .oneOf(
      [Yup.ref("new_password"), null],
      "Les deux mots de passe ne correspondent pas"
    )
    .required("La confirmation du mot de passe est obligatoire"),
});

const CompanyForm = ({ onSubmit, className }) => {
  return (
    <Formik
      initialValues={{
        current_password: "",
        new_password: "",
        re_new_password: "",
      }}
      validationSchema={ChangePasswordSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form
          id="change_password_form"
          className={className}
          onSubmit={handleSubmit}
          aria-label="form"
        >
          <Input
            id="change_password_form-current_password"
            data-testid="change_password_form-current_password"
            label="Mot de passe actuel"
            placeholder="mot de passe actuel"
            name="current_password"
            type="password"
          />
          <Input
            id="change_password_form-new_password"
            data-testid="change_password_form-new_password"
            label="Nouveau mot de passe"
            placeholder="nouveau mot de passe"
            name="new_password"
            type="password"
          />
          <Input
            id="change_password_form-re_new_password"
            data-testid="change_password_form-re_new_password"
            label="Nouveau mot de passe"
            placeholder="nouveau mot de passe"
            name="re_new_password"
            type="password"
          />
        </form>
      )}
    </Formik>
  );
};

export default CompanyForm;
