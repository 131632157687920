import { useState, createContext, useContext } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CalendarNavigation from "./CalendarNavigation";
import { colors, Euro } from "../ui";
import { DateTime } from "luxon";
import {
  getDays,
  addSegmentPosition,
  moveSegmentBeginningOfRow,
} from "./calendarActions";
import { ResizableBox } from "react-resizable";
import { FaSearchPlus, FaSearchMinus, FaCopy } from "react-icons/fa";
import { useAuth } from "../auth/authContext";

const ResizableSegment = ({ segment }) => {
  const { currentUser } = useAuth();
  const { dayWidth, resizeSegment, duplicateASegment, openTaskForSegment } =
    useContext(CalendarContext);
  const [{ isDragging }, dragRef] = useDrag({
    type: "TASK",
    item: () => ({ ...segment }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const backgroundColor = colors[segment.color].backgroundColor;
  const textColor = colors[segment.color].textColor;
  const opacity = isDragging ? "opacity-50" : "opacity-100";
  return (
    <div>
      <ResizableBox
        width={segment.width}
        height={segment.height}
        axis="x"
        resizeHandles={["e"]}
        minConstraints={[dayWidth, dayWidth]}
        onResizeStart={(event) => {
          event.preventDefault();
          event.stopPropagation();
          return;
        }}
        onResizeStop={(event, { size }) => {
          event.preventDefault();
          event.stopPropagation();
          const deltaInDays = Math.round(
            (size.width - segment.width) / dayWidth
          );
          if (deltaInDays) {
            resizeSegment(segment, deltaInDays);
          }
        }}
        draggableOpts={{
          grid: [dayWidth, dayWidth],
        }}
      >
        <div
          ref={dragRef}
          className={`flex h-full w-full ${backgroundColor} ${textColor} ${opacity} rounded`}
        >
          <div
            className="flex flex-col w-full h-full cursor-pointer overflow-hidden"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              openTaskForSegment(segment);
            }}
          >
            <div className="w-full pl-1 flex flex-col">
              <div className="h-6 w-full overflow-hidden">
                <span className="truncate">{segment.name}</span>
              </div>

              {currentUser.is_reader ? null : (
                <div className="flex items-start h-1/2">
                  <span className="text-xs">
                    {segment.toPlan ? (
                      <Euro value={segment.turnover} />
                    ) : (
                      <Euro value={segment.turnoverPerDay} suffix=" €/j" />
                    )}
                  </span>
                </div>
              )}
            </div>
            <div
              className={`absolute flex justify-end items-end p-1 ${backgroundColor}`}
              style={{
                bottom: 0,
                right: 5,
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                duplicateASegment(segment);
              }}
            >
              <FaCopy className="icon" />
            </div>
          </div>
          <div className="h-full w-2 flex-shrink-0"></div>
        </div>
      </ResizableBox>
    </div>
  );
};

const DraggableSegment = ({ segment }) => {
  return (
    <div
      style={{
        top: segment.top,
        left: segment.left,
        width: segment.width,
        height: segment.height,
      }}
      className="z-10 absolute"
    >
      <ResizableSegment segment={segment} />
    </div>
  );
};

const DroppableLine = ({ resourceId, segments, zoomIn, zoomOut, height }) => {
  const { firstDayOfInterval, dayWidth, moveSegment, openTaskAtIndex } =
    useContext(CalendarContext);
  const [, dropRef] = useDrop({
    accept: "TASK",
    drop: (segment, monitor) => {
      const { x } = monitor.getDifferenceFromInitialOffset();
      const deltaInDays = Math.round(x / dayWidth);
      const firstDayOfSegmentRelative = firstDayOfInterval.plus({
        days: Math.round(segment.left / dayWidth),
      });
      moveSegment(
        segment,
        resourceId,
        firstDayOfSegmentRelative.plus({ days: deltaInDays })
      );
    },
  });
  return (
    <div
      ref={dropRef}
      className="relative h-full w-full overflow-hidden cursor-pointer"
    >
      <div
        className="z-20 absolute flex flex-col items-around justify-around text-white hover:text-gray-900"
        style={{
          width: 32,
          height,
          right: 0,
        }}
      >
        <button
          className="btn-icon"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            zoomIn();
          }}
        >
          <FaSearchPlus className="icon" />
        </button>
        <button
          className="btn-icon"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            zoomOut();
          }}
        >
          <FaSearchMinus className="icon" />
        </button>
      </div>
      <div>
        {segments.map((s) => (
          <DraggableSegment key={s.id} segment={s} />
        ))}
      </div>
      <div
        className="absolute inset-0 bg-transparent cursor-pointer"
        onClick={(e) => {
          const rect = e.target.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const nbDays = Math.floor(x / dayWidth);
          openTaskAtIndex(
            firstDayOfInterval.plus({ days: nbDays }),
            resourceId
          );
        }}
      ></div>
    </div>
  );
};

const CalendarRow = ({ resource, isLast }) => {
  const {
    rowHeight,
    segmentMargin,
    menuWidth,
    width,
    dayWidth,
    firstDayOfInterval,
    lastDayOfInterval,
  } = useContext(CalendarContext);
  const [height, setHeight] = useState(rowHeight);
  return (
    <div
      className={`relative w-full overflow-hidden border-0 ${
        isLast ? "" : "border-b"
      }`}
      style={{ height }}
    >
      <div
        className="absolute"
        style={{
          width: menuWidth,
          height,
          left: 0,
          top: 0,
        }}
      >
        <span className="text-indigo-600 w-full h-full flex items-center justify-center text-center p-4">
          {resource.name}
        </span>
      </div>
      <div
        className="absolute"
        style={{
          width: width - menuWidth,
          left: menuWidth,
          height,
        }}
      >
        <DroppableLine
          height={height}
          zoomIn={() => {
            setHeight((height) => height * 1.2);
          }}
          zoomOut={() => {
            setHeight((height) => height * 0.8);
          }}
          resourceId={resource.id}
          segments={addSegmentPosition(resource.segments, {
            rowHeight: height,
            segmentMargin,
            dayWidth,
            firstDayOfInterval,
            lastDayOfInterval,
          })}
        />
      </div>
    </div>
  );
};

const ToPlanRow = ({ resource }) => {
  const {
    rowHeight,
    segmentMargin,
    menuWidth,
    width,
    dayWidth,
    firstDayOfInterval,
    lastDayOfInterval,
  } = useContext(CalendarContext);
  const [height, setHeight] = useState(rowHeight);
  return (
    <div
      className="relative w-full overflow-hidden border-0 border-b"
      style={{ height }}
    >
      <div
        className="absolute"
        style={{
          width: menuWidth,
          height,
          left: 0,
          top: 0,
        }}
      >
        <span className="text-indigo-600 w-full h-full flex items-center justify-center text-center p-4">
          A Planifier
        </span>
      </div>
      <div
        className="absolute"
        style={{
          width: width - menuWidth,
          left: menuWidth,
          height,
        }}
      >
        <DroppableLine
          height={height}
          zoomIn={() => {
            setHeight((height) => height * 1.2);
          }}
          zoomOut={() => {
            setHeight((height) => height * 0.8);
          }}
          resourceId={resource.id}
          segments={moveSegmentBeginningOfRow(resource.segments, {
            rowHeight: height,
            segmentMargin,
            dayWidth,
            firstDayOfInterval,
            lastDayOfInterval,
          })}
        />
      </div>
    </div>
  );
};

const CalendarRows = () => {
  const { currentUser } = useAuth();
  const { minHeight, segmentsPerResource } = useContext(CalendarContext);
  if (!segmentsPerResource)
    return (
      <div
        className="z-50 flex w-full justify-center items-center"
        style={{ minHeight }}
      ></div>
    );
  const segmentsPerResourcesFiltered = Object.values(
    segmentsPerResource
  ).filter((r) => !r.disabled);
  return (
    <div className="relative w-full">
      {segmentsPerResourcesFiltered.map((r, i) => {
        if (r.id === null) {
          return currentUser.is_reader ? null : (
            <ToPlanRow key={r.id} resource={r} />
          );
        }
        return (
          <CalendarRow
            key={r.id}
            resource={r}
            isLast={i === segmentsPerResourcesFiltered.length - 1}
          />
        );
      })}
    </div>
  );
};

const BackgroundColumns = () => {
  const { dayWidth, menuWidth, days } = useContext(CalendarContext);
  return (
    <div className="bg-red-100">
      <div
        className={`h-full absolute cursor-pointer border-r bg-white`}
        style={{
          width: menuWidth,
          left: 0,
          top: 0,
        }}
      ></div>
      {days.map((day, index) => {
        return (
          <div
            key={index}
            className={`h-full absolute cursor-pointer ${
              day.isFirstDayOfTheMonth && !day.isFirstDayOfInterval
                ? "border-l border-indigo-600"
                : ""
            }`}
            style={{
              background: day.isWeekEndOrPublicHoliday
                ? `repeating-linear-gradient(
                150deg,
                ${day.isEvenMonth ? "#ffffff" : "#f3f4f6"},
                ${day.isEvenMonth ? "#ffffff" : "#f3f4f6"} 3px,
                ${day.isEvenMonth ? "#e5e7eb" : "#d1d5db"} 3px,
                ${day.isEvenMonth ? "#e5e7eb" : "#d1d5db"} 4px
              )`
                : day.isEvenMonth
                ? "#ffffff"
                : "#f3f4f6",
              width: dayWidth,
              left: index * dayWidth + menuWidth,
            }}
          ></div>
        );
      })}
    </div>
  );
};

function Day({ day }) {
  const weekDay = day.date.toFormat("EEEE").substring(0, 2);
  const dayOfMonth = day.date.toFormat("dd");
  return (
    <div className={`flex flex-col`}>
      <div className="text-xs h-6 flex justify-center items-end">
        <span className="text-indigo-600">
          {day.isFirstDayOfTheMonth
            ? day.date.toFormat("MMM").slice(0, -1).toUpperCase()
            : ""}
        </span>
      </div>
      <div className="text-xs h-6 flex justify-center items-end">
        {day.weekDay === 1 ? `s${day.date.weekNumber}` : ""}
      </div>
      <div className="h-12 flex justify-center items-center">
        {day.isToday ? (
          <span className="text-sm capitalize border border-indigo-500 border-2 px-1 py-2 rounded">{`${weekDay} ${dayOfMonth}`}</span>
        ) : (
          <span className="text-sm capitalize">{`${weekDay} ${dayOfMonth}`}</span>
        )}
      </div>
    </div>
  );
}

function CalendarHeader() {
  const { days, dayWidth, menuWidth } = useContext(CalendarContext);
  return (
    <div className="relative w-full border-0 border-b" style={{ height: 96 }}>
      {days.map((day, index) => (
        <div
          key={index}
          className={`h-full absolute`}
          style={{
            width: dayWidth,
            left: index * dayWidth + menuWidth,
          }}
        >
          <Day day={day} />
        </div>
      ))}
    </div>
  );
}

const InnerCalendar = () => (
  <div
    id="calendar"
    className="relative w-full overflow-x-hidden border-0 border-b cursor-pointer bg-white"
  >
    <BackgroundColumns />
    <CalendarHeader />
    <CalendarRows />
  </div>
);

export const CalendarContext = createContext();

const Calendar = ({ width, ...rest }) => {
  const now = DateTime.local().startOf("day");
  const dayWidth = 64;
  const menuWidth = dayWidth * 1.5;
  const nbDays = Math.ceil((width - menuWidth) / dayWidth);
  const rowHeight = 1.5 * dayWidth;
  const segmentMargin = Math.round(0.1 * rowHeight);
  const minHeight = "50vh";
  const [date, setDate] = useState(now);
  const [showTurnover, setShowTurnover] = useState(true);
  return (
    <div className="w-full mb-16 bg-white shadow rounded-lg">
      <CalendarContext.Provider
        value={{
          width,
          now,
          dayWidth,
          menuWidth,
          nbDays,
          minHeight,
          rowHeight,
          segmentMargin,
          date,
          setDate,
          showTurnover,
          setShowTurnover,
          firstDayOfInterval: date,
          lastDayOfInterval: date.plus({ days: nbDays }),
          days: getDays(date, now, nbDays),
          ...rest,
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <CalendarNavigation />
          <div className="relative w-full">
            <InnerCalendar />
          </div>
          <div className="w-full h-16"></div>
        </DndProvider>
      </CalendarContext.Provider>
    </div>
  );
};

export default Calendar;
