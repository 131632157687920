import { Formik } from "formik";
import * as Yup from "yup";
import { Input } from "../ui";

export const CompanySchema = Yup.object({
  name: Yup.string()
    .min(2, "Le nom de votre entreprise est trop court")
    .required("Le nom de votre entreprise est obligatoire"),
  address: Yup.string()
    .min(5, "L'adresse de votre entreprise est trop courte")
    .nullable(),
  postalCode: Yup.number()
    .test(
      "is-length-of-5",
      "Un code postal est composé de 5 chiffres",
      (value) => {
        if (value) {
          return typeof value === "number" && value.toString().length === 5;
        }
        return true;
      }
    )
    .typeError("Un code postal est composé de 5 chiffres")
    .nullable(),
  taxAnniversary: Yup.string().nullable(),
  city: Yup.string().min(2, "Le nom de votre ville est trop court").nullable(),
});

const CompanyForm = ({ company, onSubmit, className }) => {
  return (
    <Formik
      initialValues={company}
      validationSchema={CompanySchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form
          id="company_form"
          className={className}
          onSubmit={(e) => {
            if (values.postalCode) {
              setFieldValue("postalCode", parseInt(values.postalCode, 10));
            }
            handleSubmit(e);
          }}
          aria-label="form"
        >
          <Input
            id="company_form-name"
            data-testid="company_form-name"
            label="Nom de votre entreprise"
            placeholder="nom de l'entreprise"
            name="name"
            className="mb-4"
          />
          <Input
            id="company_form-address"
            data-testid="company_form-address"
            label="Adresse"
            placeholder="adresse"
            name="address"
            className="mb-4"
          />
          <div className="flex -mx-2">
            <div className="w-1/2 px-2">
              <Input
                id="company_form-postalCode"
                data-testid="company_form-postalCode"
                label="Code postal"
                placeholder="code postal"
                name="postalCode"
                className="mb-4"
              />
            </div>
            <div className="w-1/2 px-2">
              <Input
                id="company_form-city"
                data-testid="company_form-city"
                label="Ville"
                placeholder="ville"
                name="city"
                className="mb-4"
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CompanyForm;
