import { useDispatch } from "react-redux";
import { Card, Header } from "../ui";
import * as api from "../services/api";
import { showSuccess, showError } from "../alerts/alertsActions";
import { useHistory } from "react-router";
import { useAuth } from "../auth/authContext";
import CompanyForm from "./CompanyForm";
import ChangePasswordForm from "./ChangePasswordForm";

const CompanySection = ({ company }) => {
  const dispatch = useDispatch();
  const { refreshCurrentUser } = useAuth();
  return (
    <div>
      <CompanyForm
        company={company}
        onSubmit={(updatedCompany) => {
          api
            .updateCompany(updatedCompany)
            .then(() => {
              dispatch(
                showSuccess(
                  "Entreprise mise à jour",
                  "Votre entreprise a été mise à jour"
                )
              );
            })
            .catch(() => {
              dispatch(
                showError(
                  "Une erreur est survenue",
                  "Il nous est impossible de sauvegarder votre entreprise pour le moment. Veuillez retentez dans quelques minutes."
                )
              );
            })
            .finally(refreshCurrentUser);
        }}
        className="mb-4"
      />
      <div className="flex items-center justify-between mb-6">
        <button type="submit" form="company_form" className="btn btn-primary">
          Mettre à jour
        </button>
      </div>
    </div>
  );
};

const ChangePasswordSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { signOut } = useAuth();
  return (
    <div>
      <ChangePasswordForm
        onSubmit={(values) => {
          api
            .setPassword(values)
            .then(() => {
              dispatch(
                showSuccess(
                  "Mot de passe mise à jour",
                  "Votre mot de passe a été mis à jour correctement. Veuillez vous reconnecter avec votre nouveau mot de passe."
                )
              );
              signOut();
              history.push("/auth/login");
            })
            .catch((error) => {
              if (error.response.status === 400) {
                const errors = Object.values(error.response.data);
                for (let index = 0; index < errors.length; index++) {
                  const error = errors[index];
                  dispatch(showError("Une erreur est survenue", error));
                }
              } else {
                dispatch(
                  showError(
                    "Une erreur est survenue",
                    "Il nous est impossible de changer votre mot de passe pour le moment. Veuillez retenter dans quelques minutes"
                  )
                );
              }
            });
        }}
      />
      <div className="flex items-center justify-between mb-6">
        <button
          type="submit"
          form="change_password_form"
          className="btn btn-primary"
        >
          Mettre à jour
        </button>
      </div>
    </div>
  );
};

const MyAccountPage = () => {
  const currentUser = useAuth().currentUser;
  return (
    <div>
      <Header />
      <main className="p-4">
        <Card className="p-10 mb-5">
          <h2 className="h2">
            Bienvenue {currentUser.name || currentUser.email}
          </h2>
          Vous pouvez mettre à jour vos informations personnelles.
        </Card>
        <div className="flex flex-col md:flex-row md:-mx-4">
          <div className="w-full md:w-1/2 md:px-4">
            <Card className="p-10 mb-5">
              <h2 className="h2">Mon entreprise</h2>
              <CompanySection company={currentUser.company} />
            </Card>
          </div>
          <div className="w-full md:w-1/2 md:px-4">
            <Card className="p-10 mb-5">
              <h2 className="h2">Changer mon mot de passe</h2>
              <ChangePasswordSection />
            </Card>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MyAccountPage;
