import {
  ITokenFromAPI,
  IUserProps,
  ICurrentUser,
  INewPassword,
  IRegisterProps,
  Document
} from "../types";
import http from "./http";
import * as localStore from "./localStore";
import { sortByOrder } from "./sort";

export function register(info: IRegisterProps): Promise<ICurrentUser> {
  return http.post("/auth/users/", info).then((response) => response.data);
}

export function createToken(user: IUserProps): Promise<ITokenFromAPI> {
  return http.post("/auth/jwt/create/", user).then((response) => response.data);
}

export function saveTokens(tokens: ITokenFromAPI) {
  return localStore.saveTokens({
    accessToken: tokens.access,
    refreshToken: tokens.refresh,
  });
}

export function removeTokens() {
  return localStore.removeTokens();
}

export function getCurrentUser(): Promise<ICurrentUser> {
  return http.get("/auth/users/me/").then((response) => {
    const currentUser = response.data;
    return getCompany().then((result) => {
      currentUser.company = result
      return currentUser;
    });
  });
}

export function resetPassword(email: string): Promise<void> {
  return http.post("/auth/users/reset_password/", { email });
}

export function setPassword({
  current_password,
  new_password,
  re_new_password,
}: INewPassword) {
  return http.post("/auth/users/set_password/", {
    current_password,
    new_password,
    re_new_password,
  });
}

export function confirmResetPassword(
  uid: string,
  token: string,
  password: string
): Promise<void> {
  return http.post("/auth/users/reset_password_confirm/", {
    uid,
    token,
    new_password: password,
    re_new_password: password,
  });
}

export function activateAccount(uid: string, token: string) {
  return http.post("/auth/users/activation/", {
    uid,
    token,
  });
}

// resource
export function getResources() {
  return http
    .get("/resources/")
    .then((response) => sortByOrder(response.data.results));
}

export function createResource(resource: any) {
  return http.post("/resources/", resource).then((response) => response.data);
}

export function updateResource(resource: any) {
  return http
    .put(`/resources/${resource.id}/`, resource)
    .then((response) => response.data);
}

export function deleteResource(resource: any) {
  return http.delete(`/resources/${resource.id}/`);
}

// task
export function getTasks() {
  return http.get("/tasks/").then((response) => response.data.results);
}

export function createTask(task: any) {
  return http.post("/tasks/", task).then((response) => response.data);
}

export function updateTask(task: any) {
  return http.put(`/tasks/${task.id}/`, task).then((response) => response.data);
}

export function deleteTask(task: any) {
  return http.delete(`/tasks/${task.id}/`);
}

// documents
export function getDocument(document: Document) {
  return http.get(`/documents/${document.id}/`);
}

export function uploadDocument(file: File) {
  return http.post(`/upload/${file.name}/`, file);
}

export function deleteDocument(document: Document) {
  return http.delete(`/documents/${document.id}/`);
}

// companies
export function getCompany() {
  return http.get("/companies/").then((response) => response.data.results[0]);
}

export function updateCompany(company: any) {
  return http.put(`/companies/${company.id}/`, company);
}

// segments
export function updateSegment(segment: any) {
  return http
    .put(`/segments/${segment.id}/`, segment)
    .then((response) => response.data);
}
