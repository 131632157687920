import { useState, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { FaTrashAlt, FaPlusSquare, FaFilePdf } from "react-icons/fa";
import {
  ColorPicker,
  Label,
  Input,
  InputEuro,
  Checkbox,
  HelpMessage,
} from "../ui";
import { getDocument, deleteDocument, uploadDocument } from "../services/api";

export const TaskSchema = Yup.object({
  name: Yup.string(),
  turnover: Yup.number()
    .typeError("Ce montant est invalide")
    .test("is-euro", "Ce montant est invalide", (value) => {
      if (value) {
        return (value + "").match(/^[0-9]+(\.[0-9]{1,2})?$/);
      }
      return true;
    })
    .required(
      "Le chiffre d'affaire est nécessaire, mettez 0 si vous ne le connaissez pas"
    ),
  weekEndWorked: Yup.boolean(),
  segments: Yup.array().of(
    Yup.object({
      start: Yup.string()
        .test(
          "is-before-or-equal-end",
          "La date de début doit être avant la date de fin",
          function (start) {
            if (start && this.parent.end) {
              return (
                DateTime.fromISO(start) <= DateTime.fromISO(this.parent.end)
              );
            }
            return false;
          }
        )
        .required("Date de début nécessaire"),
      end: Yup.string()
        .test(
          "is-after-or-equal-start",
          "La date de fin doit être après la date de début",
          function (end) {
            if (this.parent.start && end) {
              return (
                DateTime.fromISO(this.parent.start) <= DateTime.fromISO(end)
              );
            }
            return false;
          }
        )
        .required("Date de fin nécessaire"),
      resourceId: Yup.string().nullable(),
    })
  ),
});

function _update_segments(segments, segment, values) {
  return segments.map((s) => {
    if (s.id === segment.id) {
      return { ...s, ...values };
    }
    return s;
  });
}

const TaskForm = ({
  initialValues,
  onSubmit,
  className,
  resources,
  onChange,
  now = DateTime.local(),
}) => {
  const fiveDaysAfter = now.plus({ days: 4 });
  const [segments, setSegments] = useState(
    initialValues.segments
      .map((s) => ({ ...s }))
      .sort((a, b) => (a.end > b.end ? -1 : 1))
  );
  const resourcesSelectOptions = resources
    .filter((r) => !r.disabled)
    .reduce(
      (acc, resource) => {
        acc.push({
          value: resource.id,
          label: resource.name,
        });
        return acc;
      },
      [{ value: null, label: "À planifier..." }]
    );
  const [color, setColor] = useState(initialValues.color);
  const inputFilesRef = useRef(null);
  const [seeAllSegments, setSeeAllSegments] = useState(false);
  const nbSegments = segments.length;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={TaskSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setValues, errors }) => (
        <form
          id="task_form"
          className={className}
          onSubmit={(e) => {
            const turnover = parseFloat(values.turnover);
            if (isNaN(turnover)) {
              setValues({ ...values, color, segments });
            } else {
              setValues({ ...values, color, segments, turnover });
            }
            handleSubmit(e);
          }}
          aria-label="form"
        >
          <div className="flex flex-col sm:flex-row -mx-2">
            <div className="w-1/2 sm:w-1/6 flex flex-col px-2 mb-2 sm:mb-0">
              <Label htmlFor="task_color-picker">Couleur</Label>
              <ColorPicker
                id="task_color-picker"
                color={color}
                onChange={setColor}
              />
            </div>
            <div className="w-full sm:w-1/2 px-2 mb-2 sm:mb-0">
              <Input
                label="Nom"
                id="task_form-name"
                data-testid="task_form-name"
                name="name"
                autoFocus
                autoComplete="off"
                placeholder="Nom du chantier"
                className="mb-4"
              />
            </div>
            <div className="w-full sm:w-1/3 px-2 mb-2 sm:mb-0">
              <InputEuro
                id="task_form-turnover"
                data-testid="task_form-turnover"
                label="Chiffre d'affaire"
                placeholder="1000.00"
                name="turnover"
                className="mb-4"
              />
            </div>
          </div>
          <div className="flex -mx-2 mb-8">
            <div className="w-full px-2">
              <Checkbox
                name="weekEndWorked"
                data-testid="task_form-weekEndWorked"
              >
                Weekend travaillés
              </Checkbox>
            </div>
          </div>
          <div className="flex -mx-2 mb-4">
            <div className="w-full px-2">
              <Label htmlFor="files">Fichiers</Label>
              <div className="mt-1 relative">
                <button
                  className="btn btn-sm btn-icon btn-secondary lowercase"
                  type="button"
                  onClick={() => {
                    inputFilesRef.current.click();
                  }}
                >
                  <FaFilePdf className="icon mr-2" /> ajouter des fichiers
                </button>
                <input
                  className="hidden"
                  type="file"
                  multiple
                  accept="application/pdf, image/png"
                  ref={inputFilesRef}
                  onChange={(e) => {
                    const selectedFiles = [...e.target.files];
                    if (selectedFiles.length > 0) {
                      Promise.all(
                        [...e.target.files].map((file) => uploadDocument(file))
                      ).then((uploadResponses) => {
                        const newDocuments = uploadResponses.map((v) => v.data);
                        const updatedTask = {
                          ...values,
                          documents: [...values.documents, ...newDocuments],
                        };
                        setValues(updatedTask);
                        onChange(updatedTask);
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex mb-8 pl-8">
            <ul className="list-disc">
              {values.documents.map((document, i) => (
                <li key={i}>
                  <button
                    type="button"
                    onClick={() => {
                      getDocument(document).then((result) => {
                        window.open(
                          result.data.document,
                          "_blank",
                          "rel=noopener noreferrer"
                        );
                      });
                    }}
                    className="btn btn-link"
                  >
                    {document.name}
                  </button>
                  <button
                    className="text-red-600 hover:text-red-900"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteDocument(document).then(() => {
                        const newDocuments = [
                          ...values.documents.slice(0, i),
                          ...values.documents.slice(i + 1),
                        ];
                        const updatedTask = {
                          ...values,
                          documents: newDocuments,
                        };
                        setValues(updatedTask);
                        onChange(updatedTask);
                      });
                    }}
                  >
                    <FaTrashAlt className="icon ml-2 text-sm" />
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex -mx-2 mb-4">
            <div className="w-full px-2">
              <div className="flex justify-between">
                <div>
                  <h2>Périodes</h2>
                  <p className="text-xs italic">
                    {nbSegments === 0
                      ? "Un chantier est composé d'une ou plusieurs périodes"
                      : nbSegments === 1
                      ? "Ce chantier est composé d'une période"
                      : `Ce chantier est composé de ${nbSegments} périodes`}
                  </p>
                </div>
                <div>
                  <button
                    data-testid="task_form-duplicateSegment"
                    className="btn btn-sm btn-icon btn-secondary lowercase"
                    type="button"
                    onClick={() => {
                      if (nbSegments > 0) {
                        const firstSegment = segments[0];
                        setSegments([
                          { ...firstSegment, id: uuidv4() },
                          ...segments,
                        ]);
                      } else {
                        setSegments([
                          {
                            start: now,
                            end: fiveDaysAfter,
                            resourceId: null,
                            id: uuidv4(),
                          },
                        ]);
                      }
                    }}
                  >
                    <FaPlusSquare className="icon mr-2" /> ajouter une période
                  </button>
                </div>
              </div>
            </div>
          </div>
          {nbSegments === 0 && (
            <div className="h-16 border border-dark border-dashed rounded p-2 flex items-center">
              Vous n'avez aucune période associée à ce chantier
            </div>
          )}
          <div>
            {segments.map((segment, i) => {
              if (seeAllSegments || segment.selected) {
                return (
                  <div className="mb-4" key={i}>
                    <div className="flex flex-col md:flex-row -mx-2">
                      <div className="w-full md:w-1/4 px-2 mb-2 md:mb-0">
                        <Label htmlFor={`task_form-segment${i}-start`}>
                          Début
                        </Label>
                        <DatePicker
                          id={`task_form-segment${i}-start`}
                          selected={DateTime.fromISO(segment.start).toJSDate()}
                          locale="fr"
                          dateFormat="dd/MM/yyyy"
                          className="appearance-none border border-gray-400 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                          onChange={(date) => {
                            const newSegments = _update_segments(
                              segments,
                              segment,
                              {
                                start: DateTime.fromJSDate(date).toISODate(),
                              }
                            );
                            setSegments(newSegments);
                            setValues({ ...values, segments: newSegments });
                          }}
                        />
                      </div>
                      <div className="w-full md:w-1/4 px-2 mb-2 md:mb-0">
                        <Label htmlFor={`task_form-segment${i}-end`}>Fin</Label>
                        <DatePicker
                          id={`task_form-segment${i}-end`}
                          selected={DateTime.fromISO(segment.end).toJSDate()}
                          locale="fr"
                          dateFormat="dd/MM/yyyy"
                          className="appearance-none border border-gray-400 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                          onChange={(date) => {
                            const newSegments = _update_segments(
                              segments,
                              segment,
                              {
                                end: DateTime.fromJSDate(date).toISODate(),
                              }
                            );
                            setSegments(newSegments);
                            setValues({ ...values, segments: newSegments });
                          }}
                        />
                      </div>
                      <div className="flex-1 px-2 mb-2 md:mb-0">
                        <Label htmlFor={`task_form-segment${i}-resourceId`}>
                          Salarié
                        </Label>
                        <Select
                          name="resourceId"
                          classNamePrefix={`task_form-segment${i}-resourceId`}
                          menuPlacement="top"
                          isClearable
                          isSearchable
                          inputId={`task_form-segment${i}-resourceId`}
                          value={
                            segment.resourceId
                              ? {
                                  value: segment.resourceId,
                                  label: resources.find(
                                    (r) => r.id === segment.resourceId
                                  ).name,
                                }
                              : null
                          }
                          placeholder="Choisir un salarié"
                          options={resourcesSelectOptions}
                          onChange={(selectedResource) => {
                            setSegments(
                              _update_segments(segments, segment, {
                                resourceId: selectedResource
                                  ? selectedResource.value
                                  : null,
                              })
                            );
                          }}
                        />
                      </div>
                      <div className="px-2 mb-2 md:mb-0 pt-4 md:pt-5">
                        <button
                          data-testid={`task_form-segment${i}-deleteButton`}
                          type="button"
                          className={`btn btn-danger py-3 ${
                            nbSegments <= 1 ? "btn-disabled" : ""
                          }`}
                          onClick={() =>
                            setSegments(
                              segments.filter((s) => s.id !== segment.id)
                            )
                          }
                          disabled={nbSegments === 1}
                        >
                          <FaTrashAlt className="icon" />
                        </button>
                      </div>
                    </div>
                    {errors.segments && errors.segments[i] && (
                      <div>
                        <HelpMessage className="text-red-800">
                          {errors.segments[i].start}
                        </HelpMessage>
                      </div>
                    )}
                  </div>
                );
              }
              return null;
            })}
            {nbSegments > 1 && !seeAllSegments && (
              <button
                data-testid="task_form-see_all_segments"
                className="btn btn-sm btn-link lowercase"
                type="button"
                onClick={() => setSeeAllSegments(true)}
              >
                Voir les autres périodes
              </button>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default TaskForm;
