import { colors } from "../ui";
import { useSwitch } from "../hooks";

const ColorRow = ({ color, colors, onClick, dotColor = "white" }) => {
  return (
    <div className="flex justify-between">
      {Object.values(colors).map((c) => (
        <div
          key={c.name}
          data-testid={`color_picker-box_picker-${c.backgroundColor}`}
          onClick={() => onClick(c.name)}
          className={`w-4 h-4 flex items-center justify-center mr-1 mb-1 ${c.backgroundColor}`}
        >
          {color === c.name ? (
            <span
              style={{
                height: "4px",
                width: "4px",
                backgroundColor: dotColor,
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
          ) : null}
        </div>
      ))}
    </div>
  );
};

const ColorPicker = ({ color, onChange }) => {
  const { isOn: displayColorPicker, off, toggle } = useSwitch(false);
  const colorInfo = colors[color];
  const colorsList = Object.values(colors);
  return (
    <div>
      <div
        data-testid="color_picker-swatch"
        className="z-50 mt-1 form-input block w-full sm:text-sm sm:leading-5"
        onClick={toggle}
      >
        <div
          className={`h-5 w-full rounded ${colorInfo.backgroundColor}`}
        ></div>
      </div>
      {displayColorPicker ? (
        <div
          className="z-50 absolute border border-gray-400 rounded mt-1"
          data-testid="color_picker-box_picker"
        >
          <div
            className="z-40"
            style={{ position: "fixed", inset: 0 }}
            onClick={() => off()}
          ></div>
          <div className="flex bg-transparent py-1">
            <div className="z-50 pt-1 pl-1 w-full bg-white">
              <ColorRow
                colors={colorsList.filter((c) => c.hue === 200)}
                color={color}
                onClick={(colorSelected) => {
                  toggle();
                  onChange(colorSelected);
                }}
                dotColor="black"
              />
              <ColorRow
                colors={colorsList.filter((c) => c.hue === 500)}
                color={color}
                onClick={(colorSelected) => {
                  toggle();
                  onChange(colorSelected);
                }}
              />
              <ColorRow
                colors={colorsList.filter((c) => c.hue === 900)}
                color={color}
                onClick={(colorSelected) => {
                  toggle();
                  onChange(colorSelected);
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
